import Swiper, {Autoplay, EffectFade, Navigation, Pagination} from 'swiper';

Swiper.use([Navigation, Pagination, Autoplay, EffectFade]);

const initBenefitSlider = () => {
  return new Swiper('.benefit__slider', {
    loop: true,
    speed: 2000,
    spaceBetween: 40,
    slidesPerView: 1,
    simulateTouch: false,
    fadeEffect: {
      crossFade: true
    },
    autoplay: {
      delay: 4000,
      disableOnInteraction: true,
    },
    pagination: {
      el: '.swiper-benefit-pagination',
      type: 'bullets',
    },
    navigation: {
      nextEl: '.swiper-benefit-button-next',
      prevEl: '.swiper-benefit-button-prev',
    },
  })
}

export default initBenefitSlider;
