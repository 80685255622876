const toggleHeader = () => {
    const siteHeader = document.querySelector('.js-header');

    if (siteHeader) {
        const addClassName = (className, elem) => elem.classList.add(className);
        const removeClassName = (className, elem) => elem.classList.remove(className);
        let scrollPos = 0;

        document.addEventListener("scroll", () => {

            if (window.pageYOffset > 0) {
                addClassName('header--scroll', siteHeader);
            } else {
                removeClassName('header--scroll', siteHeader);
            }

            scrollPos = document.body.getBoundingClientRect().top;
        });
    }
}

export default toggleHeader
