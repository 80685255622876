import menu from "./components/menu"
import scrollToTop from "./components/scrollToTop"
import toggleHeader from "./components/toggleHeader"
import initBenefitSlider from "./components/initBenefitSlider"
import createFeedback from "./components/createFeedback";
import initCatalogSlider from "./components/initCatalogSlider"

menu()
scrollToTop()
toggleHeader()
initBenefitSlider()
createFeedback()
initCatalogSlider()
